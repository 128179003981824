
.login-main {
	height: 100%;
	background-color: #fff;

	.login-header {
		height: 10vh;
		box-shadow: 0px 0px 10px 0px rgba(57, 142, 141, 0.5);
		padding: 0 13%;
		font-size: 2.5vh;
		color: #000;
		display: flex;
		align-items: center;

		.login-logo {
			// height: 50%;
			height: 76px;
			margin-right: 30px;
		}

		img {
			height: 100%;
		}
	}

	.login-banner {
		// height: 44vh;
		height: 600px;
		background: url('../../assets/images/bg_dx.jpg') no-repeat;
		// background: url("../../assets/images/banner-bg.jpg") no-repeat;
		// background: url("../../assets/images/login_banner_bg.jpg") no-repeat;
		background-size: 100% 100%;
		position: relative;

		.login-box {
			position: absolute;
			top: 15%;
			right: 19%;
			width: 22%;
			height: 65%;
			min-width: 320px;
			padding: 10px;
			background: #ffffff4d;
			box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
			border-radius: 10px;
		}

		.login-form {
			height: 100%;
			padding: 0 7.5%;
			background-color: #fff;
			font-size: 2.3vh;
			overflow: hidden;
			overflow-y: auto;
		}

		.title-container {
			height: 28%;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.login-btn {
			width: 100%;
			height: 40px;
			padding: 0;
		}
	}

	@media screen and (max-width: 1366px) {
		.login-banner {
			height: 460px !important;
		}
		.login-box {
			top: 10% !important;
			height: 80% !important;
		}
	}

	.login-footer {
		font-size: 14px;
		color: #999;
		text-align: center;
		padding-top: 30px;
	}
}

.company-dialog {
	/deep/ .el-dialog__body {
		padding-bottom: 60px;
	}
}

.select-info {
	line-height: 60px;
	font-size: 16px; // font-weight: 600;
	text-align: center;
	padding-left: 20px;
	margin-bottom: 10px;
	.company-name {
		color: #2671cc;
	}
}

.select-btn {
	text-align: left;
	padding-left: 60px;
	background-color: #f5f7fb;
	font-weight: normal;
	border: none;
	&:hover {
		// box-shadow: 2px 2px 2px 2px #F5F7FB;
		// box-shadow: 0px 0px 4px 2px #69c4fd;
		// box-shadow: 3px 3px 0px 0px #dcdcdc;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
		color: unset;
	}
}

.select-success {
	color: #39c098;
}

.select-error {
	color: #e6a23c;
}
