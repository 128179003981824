.login-main {
  height: 100%;
  background-color: #fff;
}
.login-main .login-header {
  height: 10vh;
  box-shadow: 0px 0px 10px 0px rgba(57, 142, 141, 0.5);
  padding: 0 13%;
  font-size: 2.5vh;
  color: #000;
  display: flex;
  align-items: center;
}
.login-main .login-header .login-logo {
  height: 76px;
  margin-right: 30px;
}
.login-main .login-header img {
  height: 100%;
}
.login-main .login-banner {
  height: 600px;
  background: url('../../assets/images/bg_dx.jpg') no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.login-main .login-banner .login-box {
  position: absolute;
  top: 15%;
  right: 19%;
  width: 22%;
  height: 65%;
  min-width: 320px;
  padding: 10px;
  background: #ffffff4d;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
  border-radius: 10px;
}
.login-main .login-banner .login-form {
  height: 100%;
  padding: 0 7.5%;
  background-color: #fff;
  font-size: 2.3vh;
  overflow: hidden;
  overflow-y: auto;
}
.login-main .login-banner .title-container {
  height: 28%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-main .login-banner .login-btn {
  width: 100%;
  height: 40px;
  padding: 0;
}
@media screen and (max-width: 1366px) {
  .login-main .login-banner {
    height: 460px !important;
  }
  .login-main .login-box {
    top: 10% !important;
    height: 80% !important;
  }
}
.login-main .login-footer {
  font-size: 14px;
  color: #999;
  text-align: center;
  padding-top: 30px;
}
.company-dialog /deep/ .el-dialog__body {
  padding-bottom: 60px;
}
.select-info {
  line-height: 60px;
  font-size: 16px;
  text-align: center;
  padding-left: 20px;
  margin-bottom: 10px;
}
.select-info .company-name {
  color: #2671cc;
}
.select-btn {
  text-align: left;
  padding-left: 60px;
  background-color: #f5f7fb;
  font-weight: normal;
  border: none;
}
.select-btn:hover {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  color: unset;
}
.select-success {
  color: #39c098;
}
.select-error {
  color: #e6a23c;
}
